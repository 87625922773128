<template>
  <v-dialog
    transition="dialog-bottom-transition"
    class="b-dialog"
    :max-width="350"
    :retain-focus="false"
    :value="dialog"
    @input="closeModal"
  >
    <v-card>
      <v-toolbar color="primary" dark>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeModal">
          <v-icon>fa-solid fa-xmark</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="b-resume-modal">
        <v-avatar size="80" class="b-resume-modal--avatar">
          <v-icon color="primary" size="36">fa-solid fa-user-tag</v-icon>
        </v-avatar>
        <div class="b-resume-modal__content">
          <ul>
            <li>
              <h4>{{ model.name }}</h4>
            </li>
            <li>
              <label>Email</label>
              <span>{{ model.email }}</span>
            </li>
            <li v-if="model.phoneNumber">
              <label>Telefone</label>
              <span>{{ formatPhone(model.phoneNumber) }}</span>
            </li>
          </ul>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { formatPhone } from '@/helpers/formatting';

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
      required: true
    },
    model: {
      type: Object,
      required: true
    }
  },

  methods: {
    formatPhone,

    closeModal() {
      this.$emit('closeModal');
    }
  }
};
</script>
